import queryString from 'query-string'
import {digitalQueryParams} from '../../digital/store/digital.paths'
import {ArFilters} from './ar.actions'

export const arPathParams = {
  query: 'query',
}

export const arQueryParams = {
  query: 'q',
  bookId: 'b',
  chapterId: 'c',
  paraId: 'p',
  filterHandbook: 'f_handbook',
  filterSkriptum: 'f_skriptum',
  filterVideosOnly: 'f_videos_only',
  filterBookTitlesOnly: 'f_book_titles_only',
  page: 'page',
}

const BASE_PATH = '/arbeitsrecht'

export const arPathOverview = () => {
  return BASE_PATH
}

export const arPathSearchSimple = (userInput: string) => {
  return `${BASE_PATH}/${userInput}`
}

const appendSearchQueryParams = (queryParams: {[key: string]: string}, filters?: ArFilters, page?: number) => {
  if (filters) {
    if (filters.videoOnly) {
      delete queryParams[arQueryParams.filterHandbook]
      delete queryParams[arQueryParams.filterSkriptum]
      delete queryParams[arQueryParams.filterBookTitlesOnly]
      queryParams[arQueryParams.filterVideosOnly] = 'true'
    }
    if (filters.bookTitleOnly) {
      delete queryParams[arQueryParams.filterHandbook]
      delete queryParams[arQueryParams.filterSkriptum]
      delete queryParams[arQueryParams.filterVideosOnly]
      queryParams[arQueryParams.filterBookTitlesOnly] = 'true'
    }
    if (filters.handbook_name) {
      queryParams[arQueryParams.filterHandbook] = filters.handbook_name
    }
    if (filters.skriptum_name) {
      queryParams[arQueryParams.filterSkriptum] = filters.skriptum_name
    }
  }
  if (page) {
    queryParams[arQueryParams.page] = String(page)
  }
}

const arPath = (
  userInput: string,
  queryParams: {[p: string]: string},
  filters: ArFilters | undefined,
  page: number | undefined
) => {
  appendSearchQueryParams(queryParams, filters, page)
  const params = queryString.stringify(queryParams)
  const paramsWithSeparator = params ? `?${params}` : ''
  const userInputPathPart = userInput ? encodeURIComponent(userInput) : ''
  return `${BASE_PATH}/${userInputPathPart}${paramsWithSeparator}`
}

export const arPathSearch = (userInput: string, filters?: ArFilters, page?: number) => {
  return arPath(userInput, {}, filters, page)
}

export const arPathBook = (
  userInput: string,
  bookId: string,
  chapterId?: string,
  paraId?: string,
  filters?: ArFilters,
  page?: number
) => {
  const queryParams: {[key: string]: string} = {}
  queryParams[arQueryParams.bookId] = bookId
  if (chapterId) {
    queryParams[arQueryParams.chapterId] = chapterId
  }
  if (paraId) {
    queryParams[arQueryParams.paraId] = paraId
  }
  return arPath(userInput, queryParams, filters, page)
}

export const arPathContentResources = () => {
  return `${BASE_PATH}-all`
}

export const arPathPlusInfo = (redirectUri?: string | null) => {
  if (!redirectUri) {
    return `${BASE_PATH}plus`
  }
  return `${BASE_PATH}plus?${digitalQueryParams.redirect}=${redirectUri}`
}
